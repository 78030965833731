import { Argon2KdfConfig } from "../../auth/models/domain/kdf-config";
import { RangeWithDefault } from "../misc/range-with-default";

export enum KdfType {
  PBKDF2_SHA256 = 0,
  Argon2id = 1,
}

export const ARGON2_MEMORY = new RangeWithDefault(20, 1024, 80);
export const ARGON2_PARALLELISM = new RangeWithDefault(2, 16, 4);
export const ARGON2_ITERATIONS = new RangeWithDefault(3, 10, 6);

export const DEFAULT_KDF_TYPE = KdfType.Argon2id;
export const PBKDF2_ITERATIONS = new RangeWithDefault(800_000, 2_000_000, 1_000_000);
export const DEFAULT_KDF_CONFIG = new Argon2KdfConfig();
